<template>
  <Layout>
    <div
      class="pre-pedidos animate__animated animate__fadeIn animate__delay-1s"
      v-if="$carrinho.pedidos.length == 0 && !reservas"
    >
      <div style="text-align: center">
        <h1>{{ $t_("voce_nao_possui") }}</h1>
        <p>{{ $t_("escolha_seu_ingresso") }}</p>
        <img src="@/assets/images/loading_pedidos.jpg" width="400" />
      </div>
    </div>
    <div class="meus-pedidos-container" v-else>
      <div class="meus-pedidos-container1">
        <div
          v-if="banner.backgroundImage != ''"
          class="meus-pedidos-container2"
          :style="banner"
        >
          <h2 class="meus-pedidos-text">{{ $config.conteudo.titulo }}</h2>
          <label class="meus-pedidos-text1"
            ><span>{{ $config.conteudo.subtitulo }}</span></label
          >
        </div>

        <v-row class="d-flex justify-center px-0 my-3 mx-0" cols="12" style="width: 100%">
          <v-col cols="12" class="py-0">
            <label class="meus-pedidos-text3">{{ $t_("pedidos") }}</label>
            <h2 class="meus-pedidos-text4">{{ $t_("meus_pedidos") }}</h2>
          </v-col>
        </v-row>

        <div
          style="
            background-color: antiquewhite;
            padding: 9px;
            width: 100%;
            font-size: 16px !important;
            margin: 0px 0px 10px 0px;
            border-radius: 7px;
          "
        >
          <strong style="font-size: 13px">{{ $t_("importante") }}</strong
          ><br />
          <span>{{ $t_("porfavor") }}</span>
        </div>

        <div class="meus-pedidos-filter-and-pagination">
          <v-row cols="12" class="px-0 my-3 mx-0" style="width: 100%">
            <v-col cols="12" md="4" class="py-0">
              <v-text-field
                class="mt-3"
                type="text"
                dense
                outlined
                tabindex="1"
                :label="$t_('LOCALIZADOR') + ':'"
                v-model="localizador"
                :placeholder="$t_('INFO_LOCALIZADOR')"
                append-icon="mdi-magnify"
                v-mask="['XXXXXXXXXX']"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4"></v-col>
            <v-col cols="12" md="4" class="py-0" style="margin-left: auto">
              <v-select
                outlined
                ref="dataHorario"
                v-model="$carrinho.totalResultados"
                :items="items"
                hide-details
                :label="$t_('POR_PAGINA')"
                @input="$carrinho.atualizaCarrinho()"
              ></v-select>
            </v-col>
          </v-row>
        </div>
        <v-tabs v-model="tab" centered>
          <v-tabs-slider color="yellow"></v-tabs-slider>
          <v-tab v-for="(item, index) in tabs" :key="index">
            {{ item }}
          </v-tab>
        </v-tabs>
        <template>
          <v-tabs-items v-model="tab" style="width: 100%">
            <v-tab-item>
              <card-meuspedidos
                v-for="(compra, index) in reservas"
                :key="index"
                :pedido="compra"
              ></card-meuspedidos>
              <div
                class="pre-pedidos animate__animated animate__fadeIn animate__delay-1s"
                v-if="!reservas || reservas.length == 0"
              >
                <div style="text-align: center">
                  <h1>{{ $t_("voce_nao_possui") }}</h1>
                  <p>{{ $t_("escolha_seu_ingresso") }}</p>
                </div>
              </div>
            </v-tab-item>
            <v-tab-item>
              <card-meuspedidos
                v-for="(compra, index) in pedidos"
                :key="index"
                :pedido="compra"
              ></card-meuspedidos>
              <div
                class="pre-pedidos animate__animated animate__fadeIn animate__delay-1s"
                v-if="!pedidos || pedidos.length == 0"
              >
                <div style="text-align: center">
                  <h1>{{ $t_("voce_nao_possui") }}</h1>
                  <p>{{ $t_("escolha_seu_ingresso") }}</p>
                </div>
              </div>
            </v-tab-item>
            <v-tab-item>
              <card-meuspedidos
                v-for="(compra, index) in cancelados"
                :key="index"
                :pedido="compra"
              ></card-meuspedidos>
              <div
                class="pre-pedidos animate__animated animate__fadeIn animate__delay-1s"
                v-if="cancelados.length == 0"
              >
                <div style="text-align: center">
                  <h1>{{ $t_("voce_nao_possui") }}</h1>
                  <p>{{ $t_("escolha_seu_ingresso") }}</p>
                </div>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </template>

        <v-row
          align="center"
          class="my-3"
          style="margin: auto"
          v-if="$carrinho.paginacao > 1"
        >
          <v-col cols="12" class="py-0">
            <v-pagination
              v-model="$carrinho.pagina"
              class="justify-center flex"
              :length="$carrinho.paginacao"
              circle
              total-visible="6"
              :color="$config.dados.corprimaria"
            ></v-pagination>
          </v-col>
        </v-row>
      </div>
    </div>
  </Layout>
</template>

<script>
import _ from "lodash";
import { ENDPOINTS } from "../plugins/service/Endpoints";
import { mask } from "vue-the-mask";
export default {
  name: "MeusPedidos",
  directives: { mask },
  components: {
    Layout: () => import("@/layout/Default.vue"),
    CardMeuspedidos: () => import("@/components/card-meuspedidos"),
  },

  data() {
    return {
      reservas: [],
      pedidos: [],
      cancelados: [],
      len: 0,
      tab: 1,
      tabs: [this.$t_("AGUARDANDO"), this.$t_("PAGO"), this.$t_("CANCELADOS")],
      banner: {
        backgroundImage: "",
      },
      items: [5, 10, 25, 50, 100],
      localizador: "",
    };
  },
  watch: {
    localizador(string) {
      if (string.length > 9) {
        this.$axios
          .post(ENDPOINTS.PEDIDOSPAGINADOS, {
            pagina: this.$carrinho.pagina,
            resultados: this.$carrinho.totalResultados,
            status: this.$carrinho.statusPagina,
            search: string,
          })
          .then((response) => {
            //this.$carrinho.pedidos   = response.resultados
            this.$carrinho.paginacao = response.totalPaginas;

            if (response.resultados) {
              switch (response.resultados[0].status) {
                case "AP":
                  this.tab = 0;
                  this.reservas = response.resultados;
                  break;
                case "PA":
                  this.tab = 1;
                  this.pedidos = response.resultados;
                  break;
                case "CA":
                  this.tab = 2;
                  this.cancelados = response.resultados;
                  break;
              }
            }
          });
      }

      if (string.length < 1) {
        this.$carrinho.atualizaCarrinho();
      }
    },
    "$carrinho.pedidos"(e) {
      this.reservas = [];
      this.pedidos = [];
      this.cancelados = [];

      if (!e || e.length == 0) {
        return;
      }

      if (this.localizador.length > 9) {
        return;
      }

      switch (this.tab) {
        case 0:
          if (e[0].status == "AP") {
            this.reservas = e;
          }
          break;
        case 1:
          if (e[0].status == "PA") {
            this.pedidos = e;
          }
          break;
        case 2:
          if (e[0].status == "CA") {
            this.cancelados = e;
          }
          break;
      }
    },
    "$config.conteudo"(c) {
      this.banner.backgroundImage =
        this.$config.conteudo.imagem !== null
          ? 'url("' + this.$config.conteudo.imagem + '")'
          : "";
    },
    "$carrinho.pagina"() {
      this.$carrinho.atualizaCarrinho();
    },
    tab(tab) {
      switch (tab) {
        case 0:
          this.$carrinho.statusPagina = "AP";
          break;
        case 1:
          this.$carrinho.statusPagina = "PA";
          break;
        case 2:
          this.$carrinho.statusPagina = "CA";
          break;
      }
      this.$carrinho.atualizaCarrinho();
      this.$carrinho.pagina = 1;
      this.$carrinho.paginacao = 0;
    },
  },
  created() {
    this.$config.getConteudo("banner_meus_pedidos");
  },
};
</script>

<style lang="scss" scoped>
.pagination-button {
  padding: 8px;
  margin: 2px;
  border-radius: 3px;
  font-size: 1em;
  cursor: pointer;
}
.pre-pedidos {
  width: 100%;
  min-height: 550px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  text-align: center;
}
.meus-pedidos-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.meus-pedidos-container1 {
  flex: 0 0 auto;
  width: 924px;
  display: flex;
  padding: 15px;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
}
.meus-pedidos-container2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: 40px;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: 10px;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-start;
}
.meus-pedidos-text {
  color: var(--dl-color-gray-white);
  font-size: px;
}
.meus-pedidos-text1 {
  color: var(--dl-color-gray-white);
  font-size: 12px;
}
.meus-pedidos-container3 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: 10px;
  align-items: flex-start;
  margin-bottom: 10px;
  flex-direction: column;
  justify-content: flex-start;
}
.meus-pedidos-text3 {
  color: var(--dl-color-gray-500);
  font-size: 12px;
}
.meus-pedidos-text4 {
  color: var(--dl-color-gray-500);
  font-size: px;
}

.meus-pedidos-filter-and-pagination {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  // padding: 40px;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  // margin-bottom: 10px;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-start;
}

@media (max-width: 991px) {
  .meus-pedidos-container1 {
    width: 100%;
  }
}
@media (max-width: 479px) {
  .meus-pedidos-container3 {
    width: 100%;
  }
}
@media (max-width: 479px) {
  .botao {
    width: 100%;
  }
}
</style>
